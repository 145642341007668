<template>
  <Navbar :user="user"/>
    <div class="container pb-100 pt-100" v-if="!isLoading">
      <div class="section-title">
        <h2>Tell us More About The Course!</h2>
        <p>This will help reader/students to understand all about the course </p>
      </div>
      <div class="contact-form">
        <form id="contactForm" @submit.prevent="editCourse">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Description</label>
                <ckeditor :editor="editor" v-model="form.description"></ckeditor>
                <div v-if="errors.description" class="text-small text-danger text-start">{{ errors.description[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                  <label for="name" class="text-start">Requirements</label>
               <ckeditor :editor="editor" v-model="form.course_requirements"></ckeditor>
                <div v-if="errors.course_requirements" class="text-small text-danger text-start">{{ errors.course_requirements[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <div class="form-group text-start">
                  <label for="name" class="text-start">What You Will Learn</label>
               <ckeditor :editor="editor" v-model="form.what_will_learn"></ckeditor>
                <div v-if="errors.what_will_learn" class="text-small text-danger text-start">{{ errors.what_will_learn[0] }}</div>
              </div>
            </div>
            <div class="col-lg-12 col-md-12">
              <button type="submit" class="btn default-btn" disabled v-if="submitting">
                <span class="label">{{ value }}</span>
              </button>
              <button type="submit" class="default-btn btn" style="pointer-events: all; cursor: pointer" v-else>
                <span class="label">Save Changes</span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-else>
      <Loading />
    </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue"
import axios from 'axios'
import Loading from '@/components/Loading.vue';
import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
export default {
  components: { Navbar,Loading, ckeditor: CKEditor.component },
  data(){
    return {
      editor: ClassicEditor,
      isLoading: true,
      submitting: false,
      user: {},
      token: localStorage.getItem('user_data'),
      form:{
        course_requirements:'', description:'', what_will_learn:''
      },
      editableSubCat: false,
      categories: {},
      sub_categories: {},
      subjects: {},
      errors: {}
    }
  },
  methods:{
    editCourse(){
      this.submitting = true
      this.value = 'Please Wait...'
      let id = this.$route.params.id
      axios.post('https://apitraining.vipawaworks.com/api/course/update_course_requirements/' + id,this.form).then(response =>{
        response.data
        this.$router.push({ name: 'CourseDashboard', params: {id: id}})
      }).catch((errors) => {
          this.errors = errors.response.data.errors
          this.submitting = false
      })
    },
    getCourse(){
        let id = this.$route.params.id
        axios.get('https://apitraining.vipawaworks.com/api/course/profile/' + id).then(response => {
            this.form = response.data
        }).catch(errors => {
            this.errors = errors.response.data.errors
        })
    },
    getCategories(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_categories').then(response => {
        this.categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getSubCategories(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_sub_categories/' + this.form.course_category_id).then(response => {
        this.sub_categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getAllSubCategories(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_sub_categories').then(response => {
        this.sub_categories = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getSubjects(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_subjects/' + this.form.sub_course_category_id).then(response => {
        this.subjects = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getAllSubjects(){
      axios.get('https://apitraining.vipawaworks.com/api/course/get_all_subjects' ).then(response => {
        this.subjects = response.data
      }).catch(errors => {
        this.errors = errors.response.data.errors
      })
    },
    getUser(){
      axios.get('https://apitraining.vipawaworks.com/api/user').then(response => {
        this.user = response.data
      }).catch(errors => {
        if (errors.response.status === 401) {
          localStorage.removeItem('user_data')
          this.$router.push({ name: 'Login'})
        }
      }).finally(() => {
        setTimeout(function () {
            this.isLoading =  false
        }.bind(this), 500);
      })
    },
  },
  created(){
    document.title = `Create Course - Training`
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}` 
    this.getUser() 
    this.getCourse()    
  },
 
};
</script>

<style>
</style>